import "./styles/app.css";
import React from 'react';
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from './app';
import createBrowserHistory from 'history/createBrowserHistory'


const history = createBrowserHistory();

const renderApp = Component =>
	render(
		<Router
			history={history}>
			<Component />
		</Router>,
		document.getElementById("root")
	);

renderApp(App);



import React, { Component } from 'react';
import { NavLink, Link } from "react-router-dom";

export default class extends Component {
  render() {
    return (
      <div className="tagged-image-full-screen-container active full-height full-width" style={{display:"flex", alignItems:'center',justifyContent:'center', backgroundColor:"rgba(0,0,0,.05)"}}>
        <div className="dark-grey-background centered-text" style={{width:"100%",maxWidth:400}}>
          <div className="small-padding" style={{paddingBottom:"0px"}}>
            <a className="button full-width" target="_blank" href={'https://www.roverpass.com/c/shaded-haven-rv-park-bakersfield-ca/'}>{'Nightly/Weekly'}</a>
          </div>
          <div className="small-padding">
            <Link onClick={this.props.hideModal} className="button full-width" to={'/contact'}>{'Extended Stay'}</Link>
          </div>
        </div>
      </div>
    );
  }
}

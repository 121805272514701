import React, {Component} from 'react'
import { Route } from "react-router-dom";
import TransitionGroup from "react-transition-group/TransitionGroup";
import AnimatedSwitch from "./utils/animated_switch";
import Header from "./components/header";
import Home from "./pages/home";

export class Layout extends Component {
  constructor(props) {
      super(props);
      this.state = ({

			})
  }
  componentDidMount = () =>{
    document.getElementsByTagName("main")[0].scrollTop = 0;
  }
  render() {
    return(
      <div>
        { this.props.children }
      </div>
    )
  }
}

export default class App extends Component {
  constructor() {
      super();
			this.state = {}
  }
  componentDidMount = () =>{

  }

  render() {
   return (
	<Route
		render={({ location }) => (
      <div>
        <TransitionGroup component="main">
          <AnimatedSwitch key={location.key} location={location}>
            <Route exact path="/" render={props => (<Layout><Home /></Layout>)} />
          </AnimatedSwitch>
        </TransitionGroup>
      </div>
		)}
 />
	 );
  }
}

import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import PortalModal from './../../alpineComponents/portalModal';
import ApplyNowPopUpContent from './../applyNowPopUpContent';

import "./header.css";

export default class Header extends Component {
	constructor() {
      super();
			this.state={mobileHeader:false};

  }
	toggleMobileHeader = () =>{
		if(this.state.mobileHeader){
			this.setState({mobileHeader:false})
		}
		else{
			this.setState({mobileHeader:true})
		}
	}
	toggleModal = () =>{
	window.location.assign('https://pmnc.twa.rentmanager.com/applynow')
	}
	hideModal = () =>{
		this.setState({modalActive:false,mobileHeader:false})
	}
	render() {
		return (
      <div id="header" className="dark-grey-background">
				<div className="mobile-menu-toggle" onClick={this.toggleMobileHeader}>
					<img className="full-width" src="/images/menu-icon.png" />
				</div>
        <div className="logo-container">
          <NavLink className="logo" exact to="/" activeClassName="active">
						<img className="full-width" src="/images/shady-haven-logo.png" />
          </NavLink>
        </div>
				<div className="menu">
					<NavLink className="nav-link" to="/rates" activeClassName="active">
						<div className="menu-item uppercase">
							Rates
						</div>
					</NavLink>
					<NavLink className="nav-link" to="/amenities" activeClassName="active">
						<div className="menu-item uppercase">
							Amenities
						</div>
					</NavLink>
					<NavLink className="nav-link" to="/gallery" activeClassName="active">
						<div className="menu-item uppercase">
							Gallery
						</div>
					</NavLink>
					<NavLink className="nav-link" to="/contact" activeClassName="active">
						<div className="menu-item uppercase">
							Contact
						</div>
					</NavLink>
					<div onClick={this.toggleModal} className="nav-link active" activeClassName="active" style={{opacity:1}}>
						<div className="menu-item" style={{alignItems:'center', justifyContent:'center'}}>
							<div className="menu-item uppercase button" style={{height:'auto'}}>
								Apply Now
							</div>
						</div>
					</div>
				</div>
				{this.state.mobileHeader &&
					<div id="mobile-header" className="dark-grey-background">
						<div id="mobile-menu-close" onClick={this.toggleMobileHeader}>
							<img style={{width: "30px"}} src="/images/close-icon.png" />
						</div>
		        <div className="logo-container">
		          <NavLink className="logo" exact to="/" activeClassName="active" onClick={this.toggleMobileHeader}>
								<img style={{width: "175px"}} src="/images/shady-haven-logo.png" />
		          </NavLink>
		        </div>
						<div className="small-padding centered-text">
							<NavLink className="nav-link" to="/rates" activeClassName="active" onClick={this.toggleMobileHeader}>
								<div className="menu-item uppercase">
									Rates
								</div>
							</NavLink>
							<NavLink className="nav-link" to="/amenities" activeClassName="active" onClick={this.toggleMobileHeader}>
								<div className="menu-item uppercase">
									Amenities
								</div>
							</NavLink>
							<NavLink className="nav-link" to="/gallery" activeClassName="active" onClick={this.toggleMobileHeader}>
								<div className="menu-item uppercase">
									Gallery
								</div>
							</NavLink>
							<NavLink className="nav-link" to="/contact" activeClassName="active" onClick={this.toggleMobileHeader}>
								<div className="menu-item uppercase">
									Contact
								</div>
							</NavLink>
							<div onClick={this.toggleModal} className="nav-link active" to="/contact"  style={{opacity:1}}>
								<div className="menu-item uppercase button">
									Apply Now
								</div>
							</div>
						</div>
		      </div>
				}
				
      </div>
		)
	}
}

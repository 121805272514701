import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./modal.css";

export default class extends Component {
	constructor() {
      super();
			this.state = ({
				modalActive:false
			})
  }
	componentDidMount = () =>{
		if(this.props.modalActive){
			this.setState({
				modalActive:true
			})
		}
	}
	hideModal = () =>{
		if(this.props.modalHidden){
			this.props.modalHidden()
		}
		this.setState({
			hideModal:true
		})
		setTimeout(() => {
			this.setState({
				modalActive:false,
				hideModal:false
			})
		},500)
	}
	render() {
		var position = "fixed";
		if(this.props.positionType == "absolute"){
			position = "absolute";
		}
		var animateOut;
		if(this.state.hideModal){
			animateOut ="animate-out"
		}
		if(!this.state.modalActive) return null
		var closeImage = "/images/close-white.png";
		var theme = "dark-theme";
		if(this.props.theme == "light"){
			closeImage = "/images/close-black.png";
			theme = "light-theme";
		}
		return ReactDOM.createPortal(
			<div>
				<div onClick={this.hideModal} className={"modal-background"+" "+animateOut+" "+theme} style={{position:position}}>
					{!this.props.hideCloseButton && <img className="portal-modal-close-icon" onClick={this.hideModal} src={closeImage} />}
 				 {this.props.content}
 			 </div>
 		 </div>,
		 document.getElementById('modal-root')
	 );
	}
}

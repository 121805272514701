import React, { Component } from "react";

export default class extends Component {
  render() {
    return (
		<div className="light-grey-background">
      <div className="centered-text" style={{
        backgroundSize:'cover',
        backgroundPosition:'center',
        backgroundImage:'url(/images/background2.jpg)'
      }}>
        <div  style={{paddingTop:40, paddingBottom:40}}>
          <h4 className="white tiny-padding">{'Puamana'}</h4>
          <div className="centered-text small-max-width-container" style={{
            backgroundColor:'#136b7e',
            boxShadow:'0px 0px 40px rgba(0,0,0,.25)'
          }}>
            <img className="full-width" src="/images/employees.jpg" />
            <div style={{margin:30, padding:30, marginTop:-80,backgroundColor:'#16a5c4', position:'relative'}}>
              <h3 className="white" style={{fontFamily: 'Pacifico', textTransform:'none'}}>{"Ohana Fire Relief"}</h3>
              <div className="tiny-padding centered-text small-max-width-container">
                <p className="white">{`Aloha Puamana Friends,`}</p>
                <p className="white" style={{marginTop:20}}>{`Many of you have reached out asking how you can help provide aid to Puamana ohana colleagues who have lost their homes in the Maui wildfires. Several of our employees who resided in the Lahaina area have lost their homes.`}</p>
                <p className="white" style={{marginTop:20}}>{`We are doing everything in our power in the coming months to help them begin to rebuild their lives, we know that many of you consider the Puamana staff as part of your ohana as well, and that you'd like to help.`}</p>
                <p className="white" style={{marginTop:20}}>{`We have set up an account to specifically aide Puamana team members who have lost their homes. It goes without saying, but 100% of the proceeds collected will be distributed to Puamana staff who have been impacted. Your contribution, no matter how small, will go a long way in providing them with the much-needed assistance during this difficult period.`}</p>
                <p className="white" style={{marginTop:20}}>{`Thank you for your time and consideration. Together, we can make a positive impact on the lives of those who are facing such tremendous challenges.`}</p>
              </div>
              <p className="white"><b>{'Now Accepting Donations'}</b></p>
              <div className="row small-max-width-container">
                  <div className="small-12 medium-4 tiny-padding">
                    <a className="button white-button full-width" href="https://buy.stripe.com/test_bIY28H4C9eY6akw6op">{'$50'}</a>
                  </div>
                  <div className="small-12 medium-4 tiny-padding">
                    <a className="button white-button full-width" href="https://buy.stripe.com/test_5kA00zd8F7vE2S46oq">{'$100'}</a>
                  </div>
                  <div className="small-12 medium-4 tiny-padding">
                    <a className="button white-button full-width" href="https://buy.stripe.com/test_4gw3cLc4BaHQ8co3cg">{'$250'}</a>
                  </div>
                  <div className="small-12 medium-4 tiny-padding">
                    <a className="button white-button full-width" href="https://buy.stripe.com/test_cN2bJh9Wt2bk2S43ch">{'$500'}</a>
                  </div>
                  <div className="small-12 medium-4 tiny-padding">
                    <a className="button white-button full-width" href="https://buy.stripe.com/test_bIYdRpd8F5nwboAaEH">{'$1000'}</a>
                  </div>
                  <div className="small-12 medium-4 tiny-padding">
                    <a className="button white-button full-width" href="https://donate.stripe.com/test_7sI9B90lT2bk64g7ss">{'Other'}</a>
                  </div>
              </div>
            </div>
            <div style={{height:5, width:'100%'}}></div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}
